import { forwardRef } from "react";
import { Loading } from "~/components/vendorUI";
import { cx, cva } from "ui/css";

const overlayCSS = cva({
  base: {
    zIndex: "overlay",
    inset: 0,
    display: "grid",
    placeItems: "center",
  },
  variants: {
    inverted: {
      true: { background: "{colors.black/60}" },
      false: { background: "{colors.white/60}" },
    },
    fullPage: {
      true: { position: "fixed" },
      false: { position: "absolute" },
    },
  },
});

type LoadingOverlayProps = React.ComponentPropsWithRef<"div"> & {
  inverted?: boolean;
  fullPage?: boolean;
};

export const LoadingOverlay = forwardRef<HTMLDivElement, LoadingOverlayProps>(
  ({ inverted = false, fullPage = true, className, ...props }, ref) => {
    return (
      <div
        className={cx(overlayCSS({ inverted, fullPage }), className)}
        data-testid="loader"
        {...props}
        ref={ref}
      >
        <Loading size="24px" color={inverted ? "white" : "brandBlue"} />
      </div>
    );
  },
);
